import { PLATFORM } from 'aurelia-pal';

const elementGlobalResources = [
    PLATFORM.moduleName('resources/elements/accordion/accordion-section'),
    PLATFORM.moduleName('resources/elements/accordion/accordion'),
    PLATFORM.moduleName('resources/elements/add-policy-note/add-policy-note'),
    PLATFORM.moduleName('resources/elements/address/address'),
    PLATFORM.moduleName('resources/elements/address/address-autocomplete'),
    PLATFORM.moduleName('resources/elements/admin/admin-toggle-tools-button'),
    PLATFORM.moduleName('resources/elements/agents/agent-avatar'),
    PLATFORM.moduleName('resources/elements/agents/agent-sidebar-button'),
    PLATFORM.moduleName('resources/elements/agent-site-content/agent-site-content'),
    PLATFORM.moduleName('resources/elements/analytics/analytics-grid'),
    PLATFORM.moduleName('resources/elements/badges/badge-assignments'),
    PLATFORM.moduleName('resources/elements/badges/earned-badges'),
    PLATFORM.moduleName('resources/elements/bonus-progress/downline-bonus-progress'),
    PLATFORM.moduleName('resources/elements/bonus-progress/monthly-bonus-progress'),
    PLATFORM.moduleName('resources/elements/calendar/my-booking-calendar-button'),
    PLATFORM.moduleName('resources/elements/cards/card-reminder-button'),
    PLATFORM.moduleName('resources/elements/cards/card-toolbar'),
    PLATFORM.moduleName('resources/elements/carrier-code/carrier-code'),
    PLATFORM.moduleName('resources/elements/carrier-numbers/carrier-number'),
    PLATFORM.moduleName('resources/elements/carrier-numbers/carrier-numbers'),
    PLATFORM.moduleName('resources/elements/carrier-numbers/contracted-with-carriers'),
    PLATFORM.moduleName('resources/elements/chart-goal-progress/chart-goal-progress'),
    PLATFORM.moduleName('resources/elements/chart-bar/chart-bar'),
    PLATFORM.moduleName('resources/elements/chart-line/chart-line'),
    PLATFORM.moduleName('resources/elements/chart-pie/chart-pie'),
    PLATFORM.moduleName('resources/elements/chat/chat-button'),
    PLATFORM.moduleName('resources/elements/color-picker/color-picker'),
    PLATFORM.moduleName('resources/elements/commission-grid/commission-grid'),
    PLATFORM.moduleName('resources/elements/commissions/commission-group'),
    PLATFORM.moduleName('resources/elements/commissions/commission-list'),
    PLATFORM.moduleName('resources/elements/container-and-drawer/container-and-drawer'),
    PLATFORM.moduleName('resources/elements/content-view/content-view'),
    PLATFORM.moduleName('resources/elements/contest-standings/contest-progress'),
    PLATFORM.moduleName('resources/elements/contest-standings/contest-standings'),
    PLATFORM.moduleName('resources/elements/contest-standings/monthly-consistency'),
    PLATFORM.moduleName('resources/elements/copy-to-clipboard/copy-to-clipboard'),
    PLATFORM.moduleName('resources/elements/dashboard-builder/dashboard-builder'),
    PLATFORM.moduleName('resources/elements/date-picker/date-picker'),
    PLATFORM.moduleName('resources/elements/debt/downline-carrier-debt'),
    PLATFORM.moduleName('resources/elements/dialer/auto-dial-button'),
    PLATFORM.moduleName('resources/elements/drilldown-objects/drilldown-objects'),
    PLATFORM.moduleName('resources/elements/drop-zone/drop-zone'),
    PLATFORM.moduleName('resources/elements/email/email'),
    PLATFORM.moduleName('resources/elements/expander/expander'),
    PLATFORM.moduleName('resources/elements/expander/card-expander'),
    PLATFORM.moduleName('resources/elements/favorites/favorite'),
    PLATFORM.moduleName('resources/elements/favorites/favorite-carriers'),
    PLATFORM.moduleName('resources/elements/file-manager/file-manager'),
    PLATFORM.moduleName('resources/elements/files/files-for'),
    PLATFORM.moduleName('resources/elements/filters/advanced-column-filter'),
    PLATFORM.moduleName('resources/elements/filters/filter-input'),
    PLATFORM.moduleName('resources/elements/flex-grid/flex-grid'),
    PLATFORM.moduleName('resources/elements/forms/data-view'),
    PLATFORM.moduleName('resources/elements/google/google-map'),
    PLATFORM.moduleName('resources/elements/hero-number/hero-number'),
    PLATFORM.moduleName('resources/elements/hierarchy/contracted-in-state'),
    PLATFORM.moduleName('resources/elements/hierarchy/contracting-hierarchy'),
    PLATFORM.moduleName('resources/elements/imports/upload-file'),
    PLATFORM.moduleName('resources/elements/kpis/kpi'),
    PLATFORM.moduleName('resources/elements/kpis/kpi-number'),
    PLATFORM.moduleName('resources/elements/kpis/kpi-simple'),
    PLATFORM.moduleName('resources/elements/kpis/kpi-health'),
    PLATFORM.moduleName('resources/elements/leaderboard/leaderboard'),
    PLATFORM.moduleName('resources/elements/leads/lead-activity-timeline'),
    PLATFORM.moduleName('resources/elements/leads/lead-autocomplete'),
    PLATFORM.moduleName('resources/elements/leads/lead-kpi'),
    PLATFORM.moduleName('resources/elements/leads/lead-name'),
    PLATFORM.moduleName('resources/elements/leads/lead-script'),
    PLATFORM.moduleName('resources/elements/leads/lead-status'),
    PLATFORM.moduleName('resources/elements/lightbox/lightbox'),
    PLATFORM.moduleName('resources/elements/link-lists/link-lists'),
    PLATFORM.moduleName('resources/elements/link-lists/link-lists-section'),
    PLATFORM.moduleName('resources/elements/lob/lob-multiselect'),
    PLATFORM.moduleName('resources/elements/loader/loader'),
    PLATFORM.moduleName('resources/elements/member/member-name'),
    PLATFORM.moduleName('resources/elements/member-autocomplete/member-autocomplete'),
    PLATFORM.moduleName('resources/elements/member-levels/member-levels'),
    PLATFORM.moduleName('resources/elements/member-licenses/member-licenses'),
    PLATFORM.moduleName('resources/elements/menus/collapsed-menu'),
    PLATFORM.moduleName('resources/elements/menus/dropdown-menu'),
    PLATFORM.moduleName('resources/elements/menus/sidebar'),
    PLATFORM.moduleName('resources/elements/menus/sidebar-menu'),
    PLATFORM.moduleName('resources/elements/messages/view-messages'),
    PLATFORM.moduleName('resources/elements/metrics/metrics'),
    PLATFORM.moduleName('resources/elements/notes/notes'),
    PLATFORM.moduleName('resources/elements/notifications/notifications'),
    PLATFORM.moduleName('resources/elements/notifications/notifications-button'),
    PLATFORM.moduleName('resources/elements/onboarding/onboarding-status-chart'),
    PLATFORM.moduleName('resources/elements/onboarding-progress-tracker/onboarding-progress-tracker'),
    PLATFORM.moduleName('resources/elements/persistency-percentage/persistency-percentage'),
    PLATFORM.moduleName('resources/elements/person/person'),
    PLATFORM.moduleName('resources/elements/phone/phone'),
    PLATFORM.moduleName('resources/elements/pipelines/stages'),
    PLATFORM.moduleName('resources/elements/pipelines/pipelines'),
    PLATFORM.moduleName('resources/elements/placement-details/placement-details'),
    PLATFORM.moduleName('resources/elements/placement-percentage/placement-percentage'),
    PLATFORM.moduleName('resources/elements/policy/policy-number'),
    PLATFORM.moduleName('resources/elements/policy-autocomplete/policy-autocomplete'),
    PLATFORM.moduleName('resources/elements/policy-list/policy-list'),
    PLATFORM.moduleName('resources/elements/policy-summary/policy-mini-summary'),
    PLATFORM.moduleName('resources/elements/policy-summary/policy-summary'),
    PLATFORM.moduleName('resources/elements/popover/popover'),
    PLATFORM.moduleName('resources/elements/processing/processing-icon'),
    PLATFORM.moduleName('resources/elements/processing/processing'),
    PLATFORM.moduleName('resources/elements/production/by-agency-metrics'),
    PLATFORM.moduleName('resources/elements/production/by-agent-metrics'),
    PLATFORM.moduleName('resources/elements/production/by-carrier-metrics'),
    PLATFORM.moduleName('resources/elements/production/by-team-metrics'),
    PLATFORM.moduleName('resources/elements/production/placement-and-persistency'),
    PLATFORM.moduleName('resources/elements/production/volume-rankings'),
    PLATFORM.moduleName('resources/elements/production-summary/production-status'),
    PLATFORM.moduleName('resources/elements/production-summary/production-summary'),
    PLATFORM.moduleName('resources/elements/profile-card/profile-card'),
    PLATFORM.moduleName('resources/elements/profile-name/profile-name'),
    PLATFORM.moduleName('resources/elements/property-bag/property-bag'),
    PLATFORM.moduleName('resources/elements/quick-actions/quick-actions-button'),
    PLATFORM.moduleName('resources/elements/quick-links/quick-links-button'),
    PLATFORM.moduleName('resources/elements/refresh-icon/refresh-icon'),
    PLATFORM.moduleName('resources/elements/ryg/ryg-icon'),
    PLATFORM.moduleName('resources/elements/search/search'),
    PLATFORM.moduleName('resources/elements/sparkline/sparkline'),
    PLATFORM.moduleName('resources/elements/spinner/spinner'),
    PLATFORM.moduleName('resources/elements/tags/tags'),
    PLATFORM.moduleName('resources/elements/team-chart/team-chart'),
    PLATFORM.moduleName('resources/elements/three-dots/three-dots'),
    PLATFORM.moduleName('resources/elements/time-period-selector/time-period-selector'),
    PLATFORM.moduleName('resources/elements/to-dos/reminder-icon'),
    PLATFORM.moduleName('resources/elements/to-dos/to-dos'),
    PLATFORM.moduleName('resources/elements/toggle-switch/toggle-switch'),
    PLATFORM.moduleName('resources/elements/tooltip/tooltip'),
    PLATFORM.moduleName('resources/elements/ui/font-sizer'),
    PLATFORM.moduleName('resources/elements/ui-theme/ui-theme-mode-button'),
    PLATFORM.moduleName('resources/elements/working/working'),
    PLATFORM.moduleName('resources/elements/wysiwyg/wysiwyg'),
];

export default elementGlobalResources;